import React from 'react';
import { Layout as AntLayout } from 'antd';

import './main.less';
import { useMediaQueryDevice } from '../MediaQuery';
const { Content } = AntLayout;

export default function Main({ children }: { children: JSX.Element }): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  return (
    <Content className={`main-container ${deviceType}`}>
      <div className="main-body-wrap">
        <div className="main-body">{children}</div>
      </div>
    </Content>
  );
}
