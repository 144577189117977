import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import LayoutComponent from './components/layout';

import HomePage from './pages/home';
import './App.less';
import { Product } from './pages/product';
import { Case } from './pages/case';

function App() {
  const { deviceType } = useMediaQueryDevice();
  useEffect(() => {
    window.document.documentElement.className = deviceType;
  }, [deviceType]);

  return (
    <Router>
      <div className={`App ${deviceType}`}>
        <Switch>
          <Route exact path="/">
            <LayoutComponent>
              <HomePage></HomePage>
            </LayoutComponent>
          </Route>
          <Route exact path="/product/:productId">
            <LayoutComponent>
              <Product />
            </LayoutComponent>
          </Route>
          <Route exact path="/case/:caseId">
            <LayoutComponent>
              <Case />
            </LayoutComponent>
          </Route>
          <Route exact path="*">
            <LayoutComponent>
              <HomePage />
            </LayoutComponent>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default hot(module)(App);
