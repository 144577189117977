import React from 'react';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { ReactComponent as HearBeat } from '@/assets/svg/title_right_heartbeat.svg';
import { ReactComponent as IconNews } from '@/assets/svg/icon_news.svg';
import { ReactComponent as ArrowRight } from '@/assets/svg/icon_arrow_right.svg';
import './index.less';
export function MediaSection(): JSX.Element {
  return (
    <div className="media">
      <div className="media-container">
        <div className="media-container__title">
          <div className="media-container__title-left">
            媒体 <span className="media-container__title-left-sub">Press</span>
          </div>
          <div className="media-container__title-right">
            <HearBeat />
          </div>
        </div>
        <div className="media-container__layer">
          <div className="media-container__layer-item">
            <div className="active-border">&nbsp;</div>
            <IconNews className="media-container__layer-item-left" />

            <div className="media-container__layer-item-right">
              <div className="media-container__layer-item-right-top">
                {' '}
                习近平：加强国家生物安全风险防控和治理体系建设 提高国家生物安全治理能力
              </div>
              <a className="media-container__layer-item-right-bottom">
                <div className="media-container__layer-item-right-bottom__source">来源：新华社</div>
                <ArrowRight />
              </a>
            </div>
          </div>
          <div className="media-container__layer-item">
            <div className="active-border">&nbsp;</div>
            <IconNews className="media-container__layer-item-left" />

            <div className="media-container__layer-item-right">
              <div className="media-container__layer-item-right-top"> 中华人民共和国生物安全法</div>
              <a className="media-container__layer-item-right-bottom">
                <div className="media-container__layer-item-right-bottom__source">来源：中国人大网</div>
                <ArrowRight />
              </a>
            </div>
          </div>
        </div>
        <div className="media-container__layer">
          <div className="media-container__layer-item">
            <div className="active-border">&nbsp;</div>
            <IconNews className="media-container__layer-item-left" />

            <div className="media-container__layer-item-right">
              <div className="media-container__layer-item-right-top">
                {' '}
                国家发改委关于印发《“十四五”优质高效医疗卫生服务体系建设实施方案》的通知
              </div>
              <a className="media-container__layer-item-right-bottom">
                <div className="media-container__layer-item-right-bottom__source">来源：国家发展和改革委员会</div>
                <ArrowRight />
              </a>
            </div>
          </div>
          <div className="media-container__layer-item">
            <div className="active-border">&nbsp;</div>
            <IconNews className="media-container__layer-item-left" />

            <div className="media-container__layer-item-right">
              <div className="media-container__layer-item-right-top"> 浙江省卫生信息应用“十佳案例”评选结果</div>
              <a className="media-container__layer-item-right-bottom">
                <div className="media-container__layer-item-right-bottom__source">来源：浙江省卫生信息学会</div>
                <ArrowRight />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
