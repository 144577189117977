import React, { useEffect, useRef } from 'react';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import './index.less';
import { ProductSection } from './product';
import { CustomerCase } from './customerCase';
import { MediaSection } from './media';
import { AboutUs } from './aboutUs';

export default function HomePage(): JSX.Element {
  const { deviceType, isMobile, isDesktop } = useMediaQueryDevice();
  return (
    <>
      <div className="product-case-section">
        <ProductSection />
        <CustomerCase />
        <MediaSection />
        <AboutUs />
      </div>
    </>
  );
}
