import React from 'react';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { ReactComponent as AboutShield } from '@/assets/svg/about_shield.svg';
import { ReactComponent as IconAbout01 } from '@/assets/svg/icon_about_01.svg';
import { ReactComponent as IconAbout02 } from '@/assets/svg/icon_about_02.svg';
import { ReactComponent as IconAbout03 } from '@/assets/svg/icon_about_03.svg';
import './index.less';
export function AboutUs(): JSX.Element {
  return (
    <div className="about-us">
      <div className="about-us__container">
        <div className="about-us__container__title">
          关于我们 <span className="about-us__container__title-sub">About Us</span>
        </div>
        <div className="about-us__container__top">
          <div className="about-us__container__top-left">
            <div className="about-us__container__top-left-top">
              杭州医铠生物科技有限公司成立于2017年，坐落于杭州市未来科技城万利大厦，企业研发团队由多名具备国际视野，精通生物医药领域、软件开发领域、智能制造领域的研发与制造，以及医疗供应链管理领域的国际高端人才组成，浙江省中小科技型企业，浙大网新科技股份有限公司成员单位。公司已完成浙江省卫生健康委开发生物安全数智监管平台开发与应用，联合浙江省疾病预防控制中心共同研制生物样本云护系统，为各地卫健委提供生物安全数智监管平台，为疾控单位、各级医疗机构提供生物样本全生命周期智慧化管理，公司致力于为医疗卫生领域的生物安全提供数字化解决方案，践行用科技护卫生命健康的社会责任。
            </div>
            <div className="about-us__container__top-left-bottom">
              <div className="about-us__container__top-left-bottom-title">
                <IconAbout01 />
                产研结合 ∙ 创新应用
              </div>
              <div className="about-us__container__top-left-bottom-content-wrap">
                <div className="about-us__container__top-left-bottom-content">
                  2021年4月15日国家《生物安全法》颁布，生物安全成为国家安全非常重要的组成部分，至今全球新冠疫情仍然严重影响各国社会群众的正常生活与经济复苏。做好生物医疗领域高致病性病原微生物存储与转运中高效的安全管理将为社会稳定发展提供巨大的安全保障。公司联合生物安全权威部门共同研发智能生物安全监控系统搭载智能硬件对生物医疗领域的生物样本在转运过程中进行实时监测，轨迹追踪溯源，环境数据可靠存证，点对点智能开启，及发生异常情况下应急处置等功能模块的系统产品，可以应用于疾病预防控制中心，各级医疗机构，微生物实验室，血站系统等场景。系统产品由软件与智能硬件构成，目前在市场上具有技术先进性和新颖性，为医疗卫生领域企事业单位提供生物安全高效防护。{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="about-us__container__top-right">
            <AboutShield />
          </div>
        </div>
        <div className="about-us__container__bottom">
          <div className="about-us__container__bottom-item">
            <div className="about-us__container__bottom-item-title">
              <IconAbout02 />
              聚焦生物安全 ∙ 深耕市场
            </div>
            <div className="about-us__container__bottom-item-content-wrap">
              <div className="about-us__container__bottom-item-content">
                生物安全成为国家安全重要组成部分，意味着生物安全监管力度明显加强，公司积极参与地方标准制定，在新冠疫情仍然严重影响社会秩序的背景下，拥有符合生物安全监管要求的产品即拥有市场领先的竞争优势。浙江省疾控系统100多家省市区县单位，各级公立医疗机构4000多家，以及几十家第三方独立实验室及其他涉及高致病性病原微生物实验室，根据生物安全要求，均需配备相应的设施设备，这将产生极大的市场需求。以浙江为基础向长三角及全国推广，公司在此基础上向生物安全领域延伸产业链产品，在生物安全这一细分领域做到行业领先，利用系统应用，延伸产品上下游，拓宽业务流，建立生物安全产业链经济园。
              </div>
            </div>
          </div>
          <div className="about-us__container__bottom-item">
            <div className="about-us__container__bottom-item-title">
              <IconAbout03 />
              立足国内 ∙ 服务全球
            </div>
            <div className="about-us__container__bottom-item-content-wrap">
              <div className="about-us__container__bottom-item-content">
                公司将持续保持与国内外科研高校及行业专家深度合作，吸纳国内外尖端技术人才，建立高标准生物安全试验平台，产研结合科研工作站等研发平台，不断提升技术创新能力，利用国内高效的产业链协同，让中国技术走向世界，用中国生物安全防护技术服务全球。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
