import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import cn from '@/locales/cn.json';

const resources = {
  cn: {
    translation: cn,
  },
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'cn',
    // backend: {
    //   loadPath: `@/locales/{{lng}}/*.json`,
    // },
    react: {
      useSuspense: true,
    },
    fallbackLng: 'cn',
    preload: ['cn'],
    interpolation: { escapeValue: false },
  });

export default i18n;
