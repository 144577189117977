import { Desktop, Mobile, Tablet } from '@/components/MediaQuery';
import { Button } from 'antd';
import React, { useState } from 'react';
import Sidebar from '../sidebar';
import HeaderMenu from './menu';
import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
export function CommonMenu(): JSX.Element {
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  return (
    <>
      <div className="header-container__menu">
        <div>
          <a id="logo" href="/">
            <div className="logo">
              <Logo />
            </div>
          </a>
        </div>
        <div className="header-main">
          <Desktop>
            <>
              <HeaderMenu mode={'horizontal'}></HeaderMenu>
            </>
          </Desktop>
          <Mobile>
            <Button
              className="header-mobile-menu-btn"
              onClick={() => {
                setIsShowSidebar(true);
              }}></Button>
          </Mobile>
          <Tablet>
            <>
              <Button
                className="header-mobile-menu-btn"
                onClick={() => {
                  setIsShowSidebar(true);
                }}></Button>
            </>
          </Tablet>
        </div>
      </div>
      {isShowSidebar && <Sidebar isShowSidebar={isShowSidebar} setIsShowSidebar={setIsShowSidebar}></Sidebar>}
    </>
  );
}
