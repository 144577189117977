import React from 'react';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { ReactComponent as Product1 } from '@/assets/svg/icon_product_01.svg';
import { ReactComponent as Product3 } from '@/assets/svg/icon_product_03.svg';
import { ReactComponent as Product4 } from '@/assets/svg/icon_product_04.svg';
import { ReactComponent as ArrowRight } from '@/assets/svg/icon_arrow_right.svg';
import { ReactComponent as ChevronRight } from '@/assets/svg/icon_chevron_right.svg';
import { ReactComponent as HearBeat } from '@/assets/svg/title_right_heartbeat.svg';
import './index.less';
export function ProductSection(): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  return (
    <div className={`product ${deviceType}`}>
      <div className="product-container">
        <div className="product-container__title">
          <div className="product-container__title-left">
            产品 <span className="product-container__title-left-sub">Product</span>
          </div>
          <div className="product-container__title-right">
            <HearBeat />
          </div>
        </div>
        <div className="product-container__top">
          <div className="product-container__top-item">
            <div className="active-border">&nbsp;</div>
            <div className="product-container__top-item__top">
              <div className="product-container__top-item-icon">
                <Product1 />
              </div>
              <div className="product-container__top-item-title">生物安全数智 监管平台</div>
              <div className="product-container__top-item-desc">
                生物安全数智监管包含新发突发传染病监测、生物安全实验室分布、重点耐药菌监测、生物样本转运监管、实验室生
                ……
              </div>
            </div>
            <a href="product/monitorPlatform" className="product-container__top-item-link">
              <ArrowRight />
            </a>
          </div>
          <div className="product-container__top-item transport ">
            <div className="active-border">&nbsp;</div>
            <div className="product-container__top-item__top">
              <div className="product-container__top-item__top-title">生物样本转运 云护系统</div>
              <a className="product-container__top-item__top-link">
                more <ChevronRight />
              </a>
            </div>
            <div className="product-container__top-item__bottom">
              <a href="product/shadowX" className="product-container__top-item__bottom-product">
                ShadowX 数字黑盒子
              </a>
              <a href="product/transportBox" className="product-container__top-item__bottom-product">
                转运箱
              </a>
              <a href="product/transportCup" className="product-container__top-item__bottom-product">
                智能生物样本杯
              </a>
              <a href="product/systemPlatform" className="product-container__top-item__bottom-product">
                系统平台
              </a>
            </div>
          </div>
          <div className="product-container__top-item">
            <div className="active-border">&nbsp;</div>
            <div className="product-container__top-item__top">
              <div className="product-container__top-item-icon">
                <Product3 />
              </div>
              <div className="product-container__top-item-title">可视化系统平台 定制开发</div>
              <div className="product-container__top-item-desc">
                为医疗卫生机构各科室快速实现数字看板、数字驾驶舱、数字孪生等应用场景帮助医院实现数字化、智慧化管理 ……
              </div>
            </div>
            <a href="product/vizPlatform" className="product-container__top-item-link">
              <ArrowRight />
            </a>
          </div>
          <div className="product-container__top-item">
            <div className="active-border">&nbsp;</div>
            <div className="product-container__top-item__top">
              <div className="product-container__top-item-icon">
                <Product4 />
              </div>
              <div className="product-container__top-item-title">实验室相关设备</div>
              <div className="product-container__top-item-desc">最前沿的实验设备服务 ……</div>
            </div>
            <a href="product/labEquipments" className="product-container__top-item-link">
              <ArrowRight />
            </a>
          </div>
        </div>
        <div className="product-container__bottom"></div>
      </div>
    </div>
  );
}
