import React from 'react';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import CaseBannerImg from '@/assets/images/case_right.jpeg';
import { get as _get } from 'lodash';
import './index.less';
import { CommonMenu } from '../header/commonMenu';

export default function CaseHeader(): JSX.Element {
  const { deviceType } = useMediaQueryDevice();

  return (
    <div className={`header case-header ${deviceType}`}>
      <div className="header-container">
        <CommonMenu />
        <div className="banner">
          <div className="banner__left">
            <div className="banner__left__title">客户案例</div>
            <div className="banner__left__subTitle">Client Cases</div>
          </div>
          <div className="banner__right">
            <img src={CaseBannerImg} />
          </div>
        </div>
      </div>
    </div>
  );
}
