import React from 'react';
import { Button, Carousel } from 'antd';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import bannerTransport from '@/assets/images/banner_illus01.png';
import bannerCup from '@/assets/images/banner_illus02.png';
import bannerSys from '@/assets/images/banner_illus03.png';
import bannerShadow from '@/assets/images/banner_illus04.png';
import { ReactComponent as BannerTitle1 } from '@/assets/svg/banner_title01.svg';
import { ReactComponent as BannerTitle2 } from '@/assets/svg/banner_title02.svg';
import { ReactComponent as BannerTitle3 } from '@/assets/svg/banner_title03.svg';
import { ReactComponent as BannerTitle4 } from '@/assets/svg/banner_title03-1.svg';
import './index.less';
import { useParams } from 'react-router-dom';
import { CommonMenu } from './commonMenu';
export default function Header(): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  const { productId, caseId } = useParams<{ productId?: string; caseId?: string }>();

  return (
    <div className={`header ${productId || caseId ? 'short' : ''} ${deviceType}`}>
      <div className="header-container">
        <CommonMenu />
        {!productId && !caseId && (
          <div className="header-container__carousel">
            <Carousel>
              <div className="slide">
                <div className="slide__container">
                  <div className="slide__left">
                    <div className="slide__left__title">
                      <BannerTitle1 />
                    </div>
                    <div className="slide__left__desc">
                      生物样本从采样、转运、存储/检测、销毁 让生物样本的每一秒都在监控之下
                    </div>
                    <div className="slide__left__subtitle">医铠生物让生物样本更安全</div>
                    <a href="product/systemPlatform" className="slide__left__button">
                      More
                    </a>
                  </div>
                  <div className="slide__right">
                    <img src={bannerTransport} />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slide__container">
                  <div className="slide__left">
                    <div className="slide__left__title">
                      <BannerTitle2 />
                    </div>
                    <div className="slide__left__subtitle">高致病性病原微生物转运必备</div>
                    <div className="slide__left__desc">
                      高防护等级设计，具备安全鉴权能力 单次电量续航能力不低于365天
                    </div>
                    <a href="product/transportCup" className="slide__left__button">
                      More
                    </a>
                  </div>
                  <div className="slide__right">
                    <img src={bannerCup} />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slide__container">
                  <div className="slide__left">
                    <div className="slide__left__title">
                      <BannerTitle3 />
                    </div>
                    <div className="slide__left__desc">数字赋能 ∙ 运筹帷幄 ∙ 决胜千里</div>
                    <a href="product/transportCup" className="slide__left__button">
                      More
                    </a>
                  </div>
                  <div className="slide__right">
                    <img src={bannerSys} />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slide__container">
                  <div className="slide__left">
                    <div className="slide__left__title">
                      <BannerTitle4 />
                    </div>
                    <div className="slide__left__desc">冷链监测 ∙ 超长待机 ∙ 六模定位 全网覆盖 ∙ 一机多能</div>
                    <a href="product/shadowX" className="slide__left__button">
                      More
                    </a>
                  </div>
                  <div className="slide__right">
                    <img src={bannerShadow} />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
}
