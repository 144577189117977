import productViz from '@/assets/images/product_box_03.jpeg';
import productVizBenefits from '@/assets/svg/viz_platform_benefits.svg';
import ShadowX from '@/assets/images/shadow-x.png';
import Nebula from '@/assets/images/转运系统.png';
import 转运箱 from '@/assets/images/转运箱.png';
import 样本杯 from '@/assets/images/样本杯.png';
// import productVizPlatformOther1 from '@/assets/svg/viz_platform_other_1.svg';
export const products = {
  vizPlatform: {
    bannerTitle: '数字孪生可视化平台开发',
    bannerSubTitle:
      '为医疗卫生机构各科室快速实现数字看板、数字驾驶舱、数字孪生等应用场景帮助医院实现数字化、智慧化管理',
    bannerImg: productViz,
    benefits: {
      desc: '集成外部开源平台，融合打造数字孪生场景；支持 Echarts、Unity 3D、Unreal Engine 4、超图、高德地图、百度、天地图等；支持MySQL、Oracle、SQL Server、PostgreSQL、ClickHouse、金仓、达梦、API、CSV、JSON等多样数据源接入，可灵活设定组 件数据自动更新时间;支持回调传递参数与数据过滤器对数据进行轻量处理；基于开发者工具及规范，进行自定义组件开发，满足用户更加多元化的场景需求；支持SaaS订阅模式与私有化独立部署。 实现数字世界与物理世界的连接，实现物联感知操控、全要素数字化表达、可视化呈现数字孪生场景。',
      img: productVizBenefits,
    },
    features: [
      {
        title: '集成外部开源平台',
        subtitle: '支持 Echarts、Unity 3D、Unreal Engine 4',
      },
    ],
    scenarios: [
      {
        title: '实验室数字化管理',
        desc: '集成外部开源平台，融合打造数字孪生场景；支持 Echarts、Unity 3D、Unreal Engine 4、超图、高德地图、百度、天地图等；支持MySQL、Oracle、SQL Server、PostgreSQL、ClickHouse、金仓、达梦、API、CSV、JSON等多样数据源接入，可灵活设定组 件数据自动更新时间;支持回调传递参数与数据过滤器对数据进行轻量处理; \n基于开发者工具及规范，进行自定义组件开发，满足用户更加多元化的场景需求；支持SaaS订阅模式与私有化独立部署。 实现数字世界与物理世界的连接，实现物联感知操控、全要素数字化表达、可视化呈现数字孪生场景。',
      },
      {
        title: '医疗活动规范化监管',
        desc: '',
      },
      {
        title: '服务提升智慧化管理',
        desc: '',
      },
      {
        title: '质量控制闭环管理',
        desc: '',
      },
    ],
    others: [],
  },
  shadowX: {
    bannerTitle: 'Shadow-X数字黑盒子',
    bannerImg: ShadowX,
    features: [
      {
        title: '高灵敏度温度侦测（+/- 0.5℃温度误差',
      },
      {
        title: '高灵敏度湿度侦测（+/-5%RH 湿度误差）',
      },
      {
        title: '具备开箱报警功能',
      },
      {
        title: '具备北斗+GPS+GLONASS+Galileo+wifi+基站，六模定位功能',
      },
      {
        title: '通讯能有效穿透信号屏蔽，稳定传输信号，不发生数据丢失',
      },
      {
        title: '通讯频率与采样频率可依据需求设置',
      },
      {
        title: '具备箱体震动撞击的报警功能',
      },
      {
        title: '单次充电续航能力不低于12天',
      },
    ],
  },
  systemPlatform: {
    bannerTitle: 'Shadow-X数字黑盒子',
    bannerImg: Nebula,
    benefits: {
      desc: '生物样本转运云护系统由可视化数据管理系统平台、数字黑盒子Shadow-X、生物样本转运箱、具有远程消杀（灭活）功能的生物样本杯组成，可实现菌（毒）种等生物样本从采集、转运到入库的全生命周期监管。系统能够针对BSL-3到BSL-4等级的高致病性病源微生物样本在运输过程中发生转运环境异常、样本遗失或泄露等风险下，通过三层防护架构（生物安全可视、可溯、可控），即可视化管理系统平台，具有智能开启功能的特制生物样本转运箱，以及在生物样本发生异常情况下的远程消杀，达到生物安全风险的有效监管、防护和消除。系统平台是基于物联网、区块链技术对生物医疗领域的生物样本在转运过程中进行实时监测，轨迹追踪溯源，环境数据可靠存证，生物样本转运箱智能开启，及生物样本发生异常情况下应急处置等功能模块的系统产品，为生物医药领域生物样本安全防护提供数字化与智慧化解决方案。',
    },
  },
  transportBox: {
    bannerTitle: '生物样本转运箱',
    bannerImg: 转运箱,
    features: [
      {
        title: '符合UN2814标准',
      },
      {
        title: '外箱采用超轻航空铝镁合金',
      },
      {
        title: '内箱采用高分子保温缓冲材料',
      },
      {
        title: '配备海关密码锁',
      },
      {
        title: '标配8只智能生物样本杯',
      },
    ],
  },
  transportCup: {
    bannerTitle: '智能生物样本杯’',
    bannerImg: 样本杯,
    features: [
      {
        title:
          '启动消杀时可使样本杯5分钟内达到100℃的高温区间，并维持至少5分钟持续加热，达到菌（毒）种有效消杀（灭活）的目的',
      },
      {
        title: '采用IP67高防护等级设计，并可耐受95Kpa压差无泄漏',
      },
      {
        title: '与Shadow-X无线通讯连接稳定可靠，具备安全鉴权能力',
      },
      {
        title: '单次电量续航能力不低于365天',
      },
      {
        title: '具有装载生物样本时声、光提示',
      },
      {
        title: '表面具备抑菌能力，材质易于每次使用后消毒',
      },
    ],
  },
};
