import ZWJ1 from '@/assets/images/浙卫健-1.png';
import ZWJ2 from '@/assets/images/浙卫健-2.png';
import ZJ1 from '@/assets/images/浙疾-1.png';

export const cases = {
  浙卫健: {
    presentations: [
      {
        title: '生物安全数智监管平台',
        img: ZWJ1,
        desc: '生物安全数智监管包含新发突发传染病监测、生物安全实验室分布、重点耐药菌监测、生物样本转运监管、实验室生物安全预警、生物安全实验室核心指标监管、实验室生物安全闭环管理七大模块内容。监管平台规范分类实验室生物安全资源，整合新发突发传染病预警、微生物耐药和抗生素使用强度监测，实验室生物安全监管三块职责内容，对省域实验室生物安全治理的体机制、方式流程、手段工具进行全方位、系统性重塑。监管平台对全省实验室生物安全开展全程可追溯监管，实现从人防到人防和机防相结合的转变，切实提高省域范围内生物安全风险管控和预警处置能力，守牢生物安全防线。',
      },
      {
        title: '闭环管理平台',
        img: ZWJ2,
        desc: '作为生物安全数智监管平台中重要的监管功能，通过“管人、管样本、管设备、管实验活动”进一步强化数据整合和协同运用，提升实验室生物安全风险的感知、研判、预警、处置、阻断和消除的全链条、精细化闭环管理。',
      },
    ],
  },
  浙疾控: {
    presentations: [
      {
        title: '生物样本全生命周期监管平台',
        img: ZJ1,
        desc: '生物样本全生命周期监管，是运用现行的法律法规和技术规范，对生物样本从采集、运输、保存及使用管理的全流程、全链条的管理。智慧公卫在“健康大脑”的建设中对生物样本全生命周期的管理就是把采集到的生物样本进行标化、运输过程中的动态和保存与使用管理信息输入健康大脑，通过算法识别生物样本的安全性，发出指令及时处置的过程。根据实验室生物安全管理规范要求，对高致病性病原微生物或者疑似高致病性病原微生物样本转运加强信息化管理，实现该类生物样本送样、收样与运输数字化操作，提高样本的全程可追溯性与生物安全防护能力',
      },
    ],
  },
};
