import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as IconTriangle } from '@/assets/svg/icon_triangle_right.svg';
import { cases } from '@/constants/cases';
import { useParams } from 'react-router-dom';
import { get as _get } from 'lodash';
import './index.less';
import classNames from 'classnames';
export function Case(): JSX.Element {
  const { caseId } = useParams<{ productId?: string; caseId?: string }>();
  const caseInfo = useMemo(() => _get(cases, [caseId || ''], {}), [caseId]);
  const [selectedPresentations, setSelectedPresentations] = useState<{ title: string; desc: string; img: string }>(
    _get(caseInfo?.presentations, [0]),
  );
  useEffect(() => {
    setSelectedPresentations(_get(caseInfo?.presentations, [0]));
  }, [caseInfo?.presentations]);
  return (
    <div className="case-section">
      <div className="case-tabs">
        <div className="case-tabs-inner">
          <div className="case-tabs-tab active">案例展示</div>
          <div className="case-tabs-tab">其他</div>
        </div>
      </div>
      <div className="case-content">
        <div className="case-content-container">
          <div className="case-content__title">
            <IconTriangle />
            案例展示 <span className="case-content__title-sub">CASE PRESENTATION</span>
          </div>
          <div className="case-content-container-content">
            <div className="case-content-container-content__left">
              {caseInfo.presentations?.map((p: { title: string; img: string; desc: string }, index: number) => (
                <div
                  key={index}
                  onClick={() => setSelectedPresentations(p)}
                  className={classNames(
                    'case-presentation-tab',
                    p.title === selectedPresentations.title ? 'active' : '',
                  )}>
                  {p.title}
                </div>
              ))}
            </div>
            <div className="case-content-container-content__right">
              <img src={selectedPresentations?.img} />
              <div>{selectedPresentations?.desc} </div>
            </div>
          </div>
        </div>
      </div>

      <div className="case-content background-grey">
        <div className="case-content-container">
          <div className="case-content__title">
            <IconTriangle />
            其他 <span className="case-content__title-sub">Others</span>
          </div>
          <div className="case-content-container-content">
            <div className="case-content-container-content-left">{caseInfo?.others?.desc}</div>
            <div className="case-content-container-content-right">
              {caseInfo?.others?.imgs.map((imgSrc: string, index: number) => (
                <img key={index} src={imgSrc} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
