import React from 'react';
import { Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Desktop, useMediaQueryDevice } from '@/components/MediaQuery';
import { useCallback } from 'react';
import { GaCategory, gaEvent } from '@/utils/analytics';
import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;

export default function HeaderMenu({ mode }: { mode: 'horizontal' | 'vertical' | 'inline' }): JSX.Element {
  const { deviceType, isDesktop } = useMediaQueryDevice();
  const { t } = useTranslation();
  const onMenuClick = useCallback((e) => {
    gaEvent(GaCategory.HEADER, `click ${e.key}`);
  }, []);

  return (
    <Menu className={`header-menu ${deviceType}`} mode={mode} selectable={false} onClick={onMenuClick}>
      <SubMenu
        popupClassName={`header-menu-popup ${deviceType}`}
        key="PRODUCTS"
        title={
          <>
            {t('Menu.Product')}
            <Desktop>
              <DownOutlined />
            </Desktop>
          </>
        }>
        <Menu.Item key="nebula">
          <a href="">{t('Menu.DigitalPlatform')}</a>
        </Menu.Item>
        <Menu.Item key="box">
          <a href="">{t('Menu.TransportSystem')}</a>
        </Menu.Item>
        <Menu.Item key="dashboard">
          <a href="">{t('Menu.vSystem')}</a>
        </Menu.Item>
        <Menu.Item key="labEquip">
          <a href="">{t('Menu.labEquip')}</a>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="cases">
        <a
          href="#cases"
          rel="noreferrer noopener"
          onClick={() => {
            onMenuClick('casese');
          }}>
          {t('Menu.cases')}
        </a>
      </Menu.Item>
      <Menu.Item key="media">
        <a
          href="#media"
          onClick={() => {
            onMenuClick('Academy');
          }}>
          {t('Menu.media')}
        </a>
      </Menu.Item>
      <Menu.Item key="contact-us">
        <a
          href="#contact-us"
          rel="noreferrer noopener"
          onClick={() => {
            onMenuClick('Academy');
          }}>
          {t('Menu.contact')}
        </a>
      </Menu.Item>
    </Menu>
  );
}
