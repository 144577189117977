import React from 'react';
import { Layout as AntLayout } from 'antd';

import Header from './header';
import Footer from './footer';
import Main from './main';
import './index.less';
import { useMediaQueryDevice } from '../MediaQuery';
import { useParams } from 'react-router-dom';
import ProductHeader from './productHeader';
import CaseHeader from './caseHeader';

export default function LayoutComponent({ children }: { children: JSX.Element }): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  const { productId, caseId } = useParams<{ productId?: string; caseId?: string }>();
  return (
    <>
      <AntLayout className={`${deviceType}`}>
        {productId && <ProductHeader />}
        {caseId && <CaseHeader />}
        {!productId && !caseId && <Header />}
        <Main>{children}</Main>
        <Footer></Footer>
      </AntLayout>
    </>
  );
}
