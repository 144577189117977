import React, { useMemo, useState } from 'react';
import { ReactComponent as IconTriangle } from '@/assets/svg/icon_triangle_right.svg';
import './index.less';
import { useParams } from 'react-router-dom';
import { get as _get } from 'lodash';
import classNames from 'classnames';
import { products } from '@/constants/products';
export function Product(): JSX.Element {
  const { productId } = useParams<{ productId?: string; caseId?: string }>();
  const productInfo = useMemo(() => _get(products, [productId || ''], {}), [productId]);
  const [selectedScenario, setSelectedScenario] = useState<{ title: string; desc: string }>(
    _get(productInfo?.scenarios, [0]),
  );
  return (
    <div className="product-section">
      <div className="product-tabs">
        <div className="product-tabs-inner">
          <div className="product-tabs-tab active">产品优势</div>
          <div className="product-tabs-tab">产品特点</div>
          <div className="product-tabs-tab">使用场景</div>
          <div className="product-tabs-tab">其他</div>
        </div>
      </div>
      <div className="product-content">
        <div className="product-content-container">
          <div className="product-content__title">
            <IconTriangle />
            优势 <span className="product-content__title-sub">BENEFITS</span>
          </div>
          <div className="product-content-container-content">
            <div className="product-content-container-content__left">{productInfo?.benefits?.desc}</div>
            <div className="product-content-container-contentt__right">
              <img src={productInfo?.benefits?.img} />
            </div>
          </div>
        </div>
      </div>
      <div className="product-content background-grey">
        <div className="product-content-container">
          <div className="product-content__title">
            <IconTriangle />
            特点 <span className="product-content__title-sub">FEATURES</span>
          </div>
          <div className="product-content-container-content">
            {productInfo?.features?.map((f: { title: string; subtitle: string }, index: number) => (
              <div className="feature-item" key={index}>
                <div className="feature-item__left"></div>
                <div className="feature-item__right">
                  <div className="feature-item__right-title">{f.title}</div>
                  <div className="feature-item__right-subtitle">{f.subtitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="product-content">
        <div className="product-content-container">
          <div className="product-content__title">
            <IconTriangle />
            使用场景 <span className="product-content__title-sub">SCENARIOS</span>
          </div>
          <div className="product-content-container-content">
            <div className='"product-content-container-content__left'>
              {productInfo?.scenarios?.map((s: { title: string; desc: string }, index: number) => (
                <div
                  className={classNames('scenario-tab', selectedScenario?.title === s.title ? 'active' : '')}
                  onClick={() => {
                    setSelectedScenario(s);
                  }}
                  key={index}>
                  {s.title}
                </div>
              ))}
            </div>
            <div className='"product-content-container-content__right'>
              <div className="scenario-desc">{selectedScenario?.desc}</div>
            </div>
          </div>
        </div>
      </div>
      {productInfo?.others && (
        <div className="product-content background-grey">
          <div className="product-content-container">
            <div className="product-content__title">
              <IconTriangle />
              其他 <span className="product-content__title-sub">Others</span>
            </div>
            <div className="product-content-container-content">
              {productInfo?.others?.map((other: { title: string; img: string }, index: number) => (
                <div className="other-item" key={index}>
                  <img src={other.img} />
                  <div className="other-item-title">{other.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
