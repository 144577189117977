import React from 'react';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import CustomCase1 from '@/assets/images/custom_case1.jpg';
import CustomCase2 from '@/assets/images/custom_case2.jpg';
import './index.less';
export function CustomerCase(): JSX.Element {
  return (
    <div className="customer-cases">
      <div className="customer-cases__container">
        <a href="/case/浙疾控" className="customer-cases__container__item">
          <div className="customer-cases__container__item-title">
            客户案例
            <span className="customer-cases__container__item-title-sub">client case</span>
          </div>
          <div className="customer-cases__container__itemcontent-img">
            <img src={CustomCase1} width="100%" />
          </div>
          <div className="customer-cases__container__item-content">
            <div className="customer-cases__container__item-content-subTitle">浙江省疾病预防控制中心</div>
            <div className="customer-cases__container__item-content-title">生物样本全生命周期数智监管平台</div>
            <div className="customer-cases__container__item-content-other"></div>
          </div>
        </a>
        <a href="/case/浙卫健" className="customer-cases__container__item">
          <div className="customer-cases__container__itemcontent-img">
            <img src={CustomCase2} width="100%" />
          </div>
          <div className="customer-cases__container__item-content">
            <div className="customer-cases__container__item-content-subTitle">浙江省卫生健康委</div>
            <div className="customer-cases__container__item-content-title">浙里生安数智监管平台</div>
            <div className="customer-cases__container__item-content-other">荣获2021年浙江省卫生信息应用“十佳案例”</div>
          </div>
        </a>
      </div>
    </div>
  );
}
