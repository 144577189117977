import React, { useMemo } from 'react';
import { Default, useMediaQueryDevice } from '@/components/MediaQuery';
import { ReactComponent as IconMail } from '@/assets/svg/icon_mail.svg';
import { ReactComponent as IconPhone } from '@/assets/svg/icon_phone.svg';
import { ReactComponent as IconService } from '@/assets/svg/icon_service.svg';
import { ReactComponent as LogoCasual } from '@/assets/svg/logo_casual.svg';
import publicNumber from '@/assets/images/public_number.jpeg';
import './footer.less';
import { useParams } from 'react-router-dom';

export default function Footer(): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  const beiAnNumber = useMemo(() => (window.location.href.includes('hzyk') ? 1 : 2), []);
  const { productId, caseId } = useParams<{ productId?: string; caseId?: string }>();
  return (
    <>
      {!productId && !caseId && (
        <div className="contact-info">
          <div className="contact-info-container">
            x
            <div className="contact-info__item">
              <div className="contact-info__item-top">
                <IconMail />
                公司邮箱
              </div>
              <div className="contact-info__item-bottom">service@ecoolguard.com</div>
            </div>
            <div className="contact-info__item">
              <div className="contact-info__item-top">
                <IconPhone />
                热线电话
              </div>
              <div className="contact-info__item-bottom">(0571) 85357512</div>
            </div>
            <div className="contact-info__item card">
              <div className="contact-info__item-left">
                <IconService />
              </div>
              <div className="contact-info__item-right">
                <div className="contact-info__item-right-top">
                  联系人 &nbsp; <strong>程展</strong>
                </div>
                <div className="contact-info__item-right-bottom">联系电话 &nbsp; 15868091328</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="footer">
        <div className={`footer-container ${deviceType}`}>
          <div className="footer-body">
            <Default>
              <div className="footer-columns">
                <div className="footer-column">
                  <div className="logo">
                    <LogoCasual />
                  </div>
                </div>
                <div className="footer-column middle">
                  <div className="footer-column-item">
                    <div className="footer-column-item-header">产品</div>
                    <a className="footer-column-item-item">生物安全数智监管平台</a>
                    <a className="footer-column-item-item">生物样本转运云护系统</a>
                    <a className="footer-column-item-item">可视化系统平台定制开发 </a>
                    <a className="footer-column-item-item">实验室相关设备</a>
                  </div>
                  <div className="footer-column-item">
                    <div className="footer-column-item-header">客户案例</div>
                    <a className="footer-column-item-item">生物样本全生命周期数智监管平台</a>
                    <a className="footer-column-item-item">浙里生安数智监管平台</a>
                  </div>
                  <div className="footer-column-item">
                    <a className="footer-column-item-header">媒体</a>
                    <a className="footer-column-item-header">关于我们</a>
                  </div>
                </div>
                <div className="footer-column">
                  <div className="public-number">
                    <img src={publicNumber} width={96}></img>
                    <div className="public-number__text">公众号</div>
                  </div>
                </div>
              </div>
            </Default>
          </div>
          <div className="footer-bottom">
            <div className="left">
              Copyright © 2017-2022 Ecoolguard. All rights reserved.{' '}
              <span className="company">杭州医铠生物科技有限公司</span>
            </div>
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer" className="right">
              浙ICP备17054233号-{beiAnNumber}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
