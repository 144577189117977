import React, { useCallback, useState } from 'react';
import { animated, useSpring, config } from 'react-spring';
import HeaderMenu from '../header/menu';
import './index.less';
import { GaCategory, gaEvent } from '@/utils/analytics';
import { ReactComponent as LogoCasual } from '@/assets/svg/logo_casual.svg';
import { Button } from 'antd';

type SidebarProps = {
  isShowSidebar: boolean;
  setIsShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Sidebar(props: SidebarProps): JSX.Element {
  const [sideBarAnimate, setSideBarAnimate] = useState(false);
  const slideInStyles = useSpring({
    from: { opacity: 0, width: '0%' },
    to: {
      opacity: 1,
      width: '100%',
    },
    config: config.stiff,
    reverse: sideBarAnimate,
    onRest: () => {
      sideBarAnimate && props.setIsShowSidebar(false);
    },
  });

  return (
    <div
      className="sidebar-container"
      onClick={() => {
        setSideBarAnimate(true);
      }}>
      <animated.div
        className="sidebar"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={slideInStyles}>
        <div className="sidebar-header">
          <div className="logo">
            <LogoCasual></LogoCasual>
          </div>
          <div
            className="sidebar-close"
            onClick={() => {
              setSideBarAnimate(true);
            }}></div>
        </div>
        <div className="sidebar-body">
          <HeaderMenu mode={'inline'}></HeaderMenu>
        </div>
      </animated.div>
    </div>
  );
}
