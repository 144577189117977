import ReactGA from 'react-ga';

export enum GaCategory {
  HOMEPAGE = 'HomePage',
  HEADER = 'Header',
  FOOTER = 'Footer',
}

export function gaEvent(
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
): void {
  ReactGA.event({
    category,
    action,
    value,
    label,
    nonInteraction,
  });
}
