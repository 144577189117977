import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { ReactComponent as IconPhone } from '@/assets/svg/icon_phone.svg';
import { useParams } from 'react-router-dom';
import { products } from '@/constants/products';
import { get as _get } from 'lodash';
import './index.less';
import { CommonMenu } from '../header/commonMenu';

export default function ProductHeader(): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  const { productId } = useParams<{ productId?: string; caseId?: string }>();
  const productInfo = useMemo(() => _get(products, [productId || ''], {}), [productId]);

  return (
    <div className={`header product-header ${deviceType}`}>
      <div className="header-container">
        <CommonMenu />
        <div className="banner">
          <div className="banner__left">
            <div className="banner__left__title">{productInfo?.bannerTitle}</div>
            <div className="banner__left__subTitle">{productInfo.bannerSubTitle}</div>
            <Button className="banner__left__button">
              <IconPhone /> 联系销售
            </Button>
          </div>
          <div className="banner__right">
            <img src={productInfo.bannerImg} />
          </div>
        </div>
      </div>
    </div>
  );
}
